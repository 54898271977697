.contact-container {
  display: flex;
  justify-content: center;
}

.contact-form-container {
  padding: 3rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  margin: 2rem;
  height: max-content;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* add this */
}

.contact-names {
  display: flex;
  flex-wrap: wrap;
}

.contact-input-wrapper {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 100%;
}

.contact-label {
  font-family: "Montserrat";
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.contact-input {
  width: 100%;
  padding: 12px 20px;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.contact-submit {
  margin: 2rem auto 1rem;
  background-color: rgba(185, 144, 116);
  color: rgba(51, 26, 18);
  cursor: pointer;
  border-radius: 10px;
  border-color: rgba(185, 144, 116);
  font-size: 1.2rem;
  font-family: "Montserrat";
  padding: 0.5rem 1rem;
  font-weight: bold;
  width: 100%;
}

.contact-submit:hover {
  background-color: rgba(160, 97, 48);
  border-color: rgba(160, 97, 48);
}

.contact-form-container:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
}

.contact-subgroup {
  display: flex;
}

.contact-phone {
  padding-left: 0.5rem;
}

.contact-email {
  padding-left: 0.5rem;
  line-height: 3.3;
  text-decoration: underline;
}

@media (max-width: 850px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-form-container {
    margin: 1rem;
    width: 90%;
  }
}
