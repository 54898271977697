.profile-page-title {
  text-align: center;
  font-size: 48px;
  font-family: "Montserrat";
}

.card-wrapper {
  margin-left: 7.5cm;
  margin-right: 7.5cm;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 590px) {
  .card-wrapper {
    margin-left: 0.5cm;
    margin-right: 0.5cm;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
