.footer-background {
  background-color: rgba(51 26 18);
}

.footer-container {
  padding-top: 0.5cm;
  display: flex;
  align-items: center;
}

.foot-all-links {
  padding: 10px 20px 10px 20px;
}

.foot-links {
  color: rgba(185 144 116);
  font-size: 20px;
  font-family: "Montserrat";
  text-decoration: none;
  font-weight: bold;
  padding: 15px;
}

.foot-links:hover {
  color: rgba(160 97 48);
  cursor: pointer;
}

.foot-logo {
  max-width: 100%;
  height: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.foot-logo:hover {
  cursor: pointer;
}

.foot-button-div {
  position: absolute;
  right: 50px;
}

.foot-donate-button {
  background-color: rgba(185 144 116);
  color: rgba(51 26 18);
  padding: 5px 20px;
  margin: 10px 0px;
  cursor: pointer;
  border-radius: 10px;
  border-color: rgba(185 144 116);
  font-size: 25px;
  font-family: "Montserrat";
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
}

.foot-donate-button:hover {
  background-color: rgba(160 97 48);
  border-color: rgba(160 97 48);
}

@media (max-width: 1279px) {
  .footer-container {
    display: block;
  }

  .foot-links {
    text-align: center;
    padding: 10px;
  }

  .foot-button-div {
    padding-top: 0.5cm;
    position: static;
    text-align: center;
  }

  .foot-logo {
    display: flex;
    padding-bottom: 5px;
    margin: 0 auto;
  }

  .footer-bottom {
    align-items: center;
  }

  .foot-hw {
    position: unset;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    padding-left: 0px !important;
    padding-bottom: 10px;
    align-self: center;
    margin-bottom: 10px;
  }

  .footer-legal {
    text-align: center;
  }
}

.foot-hw:hover {
  cursor: pointer;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.foot-hw {
  position: relative;
  padding-left: 30px;
  align-self: flex-start;
  bottom: -15px;
}

.footer-legal {
  color: rgba(185 144 116);
  padding: 0;
  margin: 0 auto;
  padding-bottom: 5px;
  align-items: center;
  font-family: "Montserrat";
  font-size: 12px;
}
