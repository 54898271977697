.hw-center {
  margin-top: 1cm;
  text-align: center;
}

.hw-sec-descr {
  text-align: center;
  margin: 0;
}

.hw-obj-title {
  text-align: center;
  margin: 20px 0 0 0;
}

.hw-obj-descr {
  text-align: center;
  margin: 0;
  text-decoration: underline;
}

.hw-ltr-logo {
  padding-top: 2.3cm;
  display: flex;
  justify-content: center;
}

.hw-back-button {
  position: absolute;
  left: 0;
  top: 3cm;
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  border-radius: 50%;
  padding: 0;
  margin-left: 20px;
}

.hw-back-button:hover {
  background-color: rgba(245 244 244);
}

.hw-vid {
  margin: 50px 0px 5px 00px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 315px;
  max-width: 560px;
}

.hw-vid iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hw-title {
  text-align: center;
  margin: 1cm 3cm;
}

.hw-video-desc {
  text-align: center;
  margin: 0;
}

.hw-sec-title {
  text-align: center;
  margin-top: 2cm;
  margin-bottom: 0;
}

.hw-button-loc {
  margin: 20px;
  text-align: center;
}

.hw-download-button {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: rgba(185 144 116);
  border-radius: 10%;
  padding: 10px;
}

.hw-download-button:hover {
  background-color: rgba(160 97 48);
}

.hw-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
