.about-profile {
  margin-bottom: 5rem;
}

.about-verse-one {
  background-color: rgb(115 101 114);
  margin: auto;
  width: 100%;
  margin: 2cm auto;
}

.about-verse-two {
  background-color: rgb(196 128 122);
  margin: auto;
  width: 100%;
  margin: 2cm auto;
}

.about-verse-text {
  font-family: "Montserrat";
  max-width: 30cm;
  margin: 0 auto;
  color: white;
  font-size: 25px;
  padding: 1cm;
  text-align: center;
}

.about-verse-verse {
  font-size: 20px;
}
