.intro-title {
  text-align: center;
  margin-bottom: 0px;
}

.intro-intro {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 2cm;
}

.hw-ltr-logo {
  padding-top: 2.3cm;
  display: flex;
  justify-content: center;
}

.hw-back-button {
  position: absolute;
  left: 0;
  top: 3cm;
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: inherit;
  border-radius: 50%;
  padding: 0;
  margin-left: 20px;
}

.hw-back-button:hover {
  background-color: rgba(245 244 244);
}

.intro-vid {
  margin: 50px 0px 5px 00px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 315px;
  max-width: 560px;
}

.intro-vid iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro-video-desc {
  text-align: center;
  margin: 0;
}

.intro-desc {
  text-align: center;
  margin: 0px;
}

.intro-books-title {
  text-align: center;
}

.intro-books {
  margin: 0;
  text-align: center;
}

.intro-watch-title {
  text-align: center;
}

.intro-watch-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.intro-watch-col {
  padding: 0px 20px 0px 20px;
}

.intro-movie {
  margin: 0;
  text-align: center;
}

.intro-echo-title {
  text-align: center;
}

.intro-echo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.intro-echo-col {
  /* padding: 0px 20px; */
  max-width: 200px;
}

.intro-echo-ppl {
  text-align: center;
  margin: 0;
}

.intro-disclaim {
  text-align: center;
}

.intro-button-loc {
  margin: 20px;
  text-align: center;
}

.intro-download-button {
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: rgba(185 144 116);
  border-radius: 10%;
  padding: 10px;
}

.intro-download-button:hover {
  background-color: rgba(160 97 48);
}

.hw-intro-workbook-spacing {
  padding: 0px 100px;
}

.hw-intro-download-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2cm;
}

.hw-intro-download-img:hover {
  border: 1px solid gray;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
