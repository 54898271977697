.main-verse-one {
  background-color: rgb(128 76 77);
  width: 100%;
  margin: 2cm auto;
}

.main-verse-text {
  font-family: "Montserrat";
  font-size: 25px;
  max-width: 30cm;
  text-align: center;
  margin: 0 auto;
  padding: 1cm;
  text-align: center;
  color: white;
}

.main-verse-verse {
  font-size: 20px;
}

.main-verse-two {
  background-color: rgb(82 133 130);
  margin: 2cm auto;
  width: 100%;
}
